.headerContainer {
    display: flex;
    align-self: flex-start;
    align-items: center;
    justify-content: center;
    height: 10%;
    text-align: center;
    background-color: #1B263B;
    color: white;
}

.headerContainer h3 {
    width: fit-content;
    height: fit-content;
    font-size: 23px;
    margin: 0;
}