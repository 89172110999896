.newEvent {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: fit-content;
}

.newEvent div {
    display: flex;
    border: 1px solid black;
    margin-top: 6%;
    width: 86%;
    height: 30%;
    border-radius: 15px;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
}

.newEvent div div {
    border: none !important;
    margin-top: 0 !important;
    width: 100%;
}

.newEvent input {
    width: 90%;
    padding-left: 2%;
}

#submit {
    margin: 5% 0%;
}

.newEventTitle {
    text-align: center;
    margin-bottom: 0 !important;
}