.event {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: fit-content;
}

.event div {
    display: flex;
    border: 2px solid black;
    margin-top: 2%;
    width: 86%;
    height: 30%;
    border-radius: 15px;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
}

.event div h2 {
    width: 100%;
}

.event div div {
    border: none !important;
    margin-top: 0 !important;
    width: 100%;
    height: 50%;
}

.eventTitle {
    text-align: center;
    margin-bottom: 0 !important;
    margin-top: 15px;
}

.eventDate {
    text-align: center;
    margin-top: -45px !important;
    width: 100%;
    font-size: large;
    margin-bottom: 0;
}

.eventPara {
    text-align: center;
    font-size: x-large;
    width: 90%;
}

.inviteButton {
    position: relative;
    top: -90px;
    left: 135px;
    height: 50px;
    border: none;
    background: none;
}

.icon {
    width: 100%;
    height: 100%;
    text-decoration: none !important;
    color: black !important;
}