.loginContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    height: 81%;
}

.loginContainer > * {
    height: fit-content;
}

.form {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.input {
    display: flex;
    flex-wrap: wrap;
    width: 80%;
    justify-content: center;
    align-items: center;
}

input {
    width: 100%;
    height: 50px;
    font-size: x-large;
}

.button {
    width: 80%;
    font-size: xx-large;
    text-align: center;
}

.loginErrorContainer {
    margin-top: 30%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}