.outerBox {
    width: 100%;
    height: 100%;
    border: 2.5px solid black;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.outerBox div {
    border: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
}

.polaroidDiv {
    width: 90%;
    height: 50vh;
    margin: 6% 0% 6%;
    background-color: whitesmoke;
}

.polaroidDiv div {
    width: 80%;
    height: 70%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 190%;
    align-self: flex-start;
    margin-top: 10%;
}

.titleBox {
    width: 100%;
    height: 10%;
    align-self: flex-start;
    justify-content: space-between !important;
}

.party {
    margin-left: 5%;
}

.date {
    margin-right: 5%;
}

.icons {
    width: 20%;
    height: 90%;
}

.bottomBox > * {
    height: fit-content;
}

.bottom {
    border: none !important;
}

.smallBox {
    height: 100%;
    width: 100%;
}