.eventContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    overflow: scroll;
    height: 81%;
}

.eventContainer > * {
    margin: 5% 0%;
}

.loading {
    width: 100%;
    height: 89%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading h2 {
    width: fit-content;
}