.landingContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    overflow: scroll;
    height: 81%;
}

.landingContainer > * {
    margin: 15% 0%;
}

.landingContainer > *:first-child {
    margin: 13% 0%;
}

.loading {
    width: 100%;
    height: 89%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading h2 {
    width: fit-content;
}

.login {
    width: 100%;
    margin-top: 100%;
    text-align: center;
    text-decoration: none !important;
    color: black !important;
}

.logout {
    width: 100%;
    margin-top: 100%;
    text-align: center;
    text-decoration: none !important;
    color: black !important;
}