.footerContainer {
    display: flex;
    align-self: flex-end;
    justify-content: space-evenly;
    align-items: center;
    height: 9%;
    background-color: #284b63;
    color: white;
}

.footerContainer > * {
    height: fit-content;
}