.portrait-container{
    width: 85%;
    height: 80%;
}

.outerBox {
    width: 100%;
    height: 100%;
    border: 2.5px solid black;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.outerBox div {
    border: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
}

.imageDiv {
    width: 100%;
    height: 79%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.titleBox {
    width: 100%;
    height: 10%;
    align-self: flex-start;
    justify-content: space-between !important;
}

.titleBox > * {
    height: fit-content;
}

.title {
    margin-left: 5%;
}

.major {
    margin-right: 5%;
}

.icons {
    width: 20%;
    height: 90%;
}

.bottomBox {
    align-self: flex-end;
    width: 100%;
    height: 10%;
}

.bottom {
    border: none !important;
}

.smallBox {
    height: 100%;
    width: 100%;
}

#venmo {
    height: 89%;
}

#insta {
    height: 97%;
}

#snap {
    height: 97%;
}

#cashapp {
    height: 83%;
}