.App {
  width: 100vw;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.App > *{
  width: 100vw;
}
