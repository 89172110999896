.PolaroidWall-container {
    overflow: scroll;
    height: 81%;
}

.construction {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: xx-large;
    font-weight: 700;
}